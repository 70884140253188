import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'

const swalExtended = Swal.mixin({
    customClass: {
        actions: 'flex space-x-1',
        confirmButton: 'button text-white bg-theme-1',
        cancelButton: 'button text-white bg-theme-24'
    },
    buttonsStyling: false
});

export default swalExtended;